import React, { useState } from 'react';
import {
  ToggleButton,
  Button,
  Collapse,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  Card,
  Alert,
  CardContent,
  Grid,
  TextField,
  Typography,
  FormControl,
  Stack,
  CardActions,
  Link,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { findEstablecimientos } from '../../services/Busquedas/Prestadores';
import LoadingButton from '../../@iosper/components/LoadingButton';
import { useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import {
  HighlightOff,
  KeyboardArrowDown,
  KeyboardArrowUp,
  LocalPhone,
  FmdGood,
} from '@mui/icons-material';
import CustomizedSnackbars from '../Notifications/SnackBar';
import { useWidth } from '../../@iosper/utils/UseWidth';
import LocalidadAutocomplete from '../Common/LocalidadAutocomplete';
import TipoEstablecimientoAutocomplete from './TipoEstablecimientoAutocomplete';
import ButtonBack from '../Breadcrumb/ButtonBack';
import { VideoModal } from '../Common/VideoModal';

const BuscarEstablecimiento = () => {
  const [loading, setLoading] = useState(false);
  const [alertResultFind, setAlertResultFind] = useState('');
  const [error, setError] = useState('');
  const [prestadores, setPrestadores] = useState([]);
  const [visibleFind, setVisibleFind] = useState(true);
  const screenWidth = useWidth();
  const [selectedLocalidad, setSelectedLocalidad] = useState(null);
  const [selectedTipoEstablecimiento, setSelectedTipoEstablecimiento] = useState(null);
  const [keyComponentLocalidad, setKeyComponentLocalidad] = useState(0);
  const [keyComponentTipoEstablecimiento, setKeyComponentTipoEstablecimiento] = useState(1);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const find = async (data, e) => {
    e.preventDefault();
    setLoading(true);
    setAlertResultFind('');
    setError('');

    if (data.nombre === '' && selectedLocalidad === null && selectedTipoEstablecimiento === null) {
      setError('Debes ingresar por lo menos un criterio para realizar la búsqueda');
      setLoading(false);
    } else {
      const result = await findEstablecimientos({
        nombre: data.nombre,
        idLocalidad: selectedLocalidad?.localidadVO.idLocalidad,
        idTipoEstablecimiento: selectedTipoEstablecimiento?.idTipoEstablecimiento,
      });
      if (result && result.length > 0) {
        setPrestadores(result);
        setVisibleFind(false);
      } else {
        setAlertResultFind('No se han encontrado establecimientos');
      }
      setLoading(false);
    }
  };

  const clean = () => {
    setKeyComponentLocalidad(1 + Math.random() * (1000 - 1));
    setKeyComponentTipoEstablecimiento(1 + Math.random() * (1000 - 1));
    setSelectedLocalidad(null);
    setSelectedTipoEstablecimiento(null);
    reset();
  };

  const handleBuscarOtro = () => {
    setPrestadores([]);
    setVisibleFind(true);
    clean();
  };

  const DataCollapseDrawer = (props) => {
    const { establecimiento } = props;
    const [open, setOpen] = React.useState(false);

    return (
      <Box sx={{ width: '100%' }}>
        <ToggleButton
          aria-label='expand row'
          value='check'
          onChange={() => {
            setOpen(!open);
          }}
          sx={{ border: 0, p: 0 }}
          fullWidth={true}
        >
          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={2}
            flexWrap='nowrap'
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            <Typography
              variant='body1'
              align='left'
              gutterBottom={false}
              sx={{ color: '#000', textTransform: 'none' }}
            >
              {`${establecimiento.nombre || ''}`}
            </Typography>
          </Grid>
        </ToggleButton>

        <Collapse in={open} timeout='auto' unmountOnExit>
          <Box sx={{ margin: 1 }}>
            <List sx={{ py: 0 }}>
              {establecimiento?.domicilio && (
                <ListItem
                  component='a'
                  href={`https://www.google.com/maps/search/${encodeURIComponent(
                    establecimiento?.domicilio + ', ' + establecimiento?.localidad,
                  )}`}
                  sx={{ py: 0 }}
                >
                  <ListItemIcon sx={{ color: '#B12823' }}>
                    <FmdGood />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ margin: -3 }}
                    primary={
                      <Typography
                        variant='body2'
                        sx={{ color: '#005a55', textDecoration: 'underline' }}
                      >
                        {`${establecimiento?.domicilio || ''}, ${establecimiento?.localidad || ''}`}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
              {establecimiento?.telefono && (
                <ListItem
                  component='a'
                  href={`tel:${establecimiento?.telefono || ''}`}
                  sx={{ py: 0 }}
                >
                  <ListItemIcon sx={{ color: '#B12823' }}>
                    <LocalPhone />
                  </ListItemIcon>
                  <ListItemText
                    sx={{ margin: -3 }}
                    primary={
                      <Typography
                        variant='body2'
                        sx={{ color: '#005a55', textDecoration: 'underline' }}
                      >
                        {`${establecimiento?.telefono || ''}`}
                      </Typography>
                    }
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </Collapse>
      </Box>
    );
  };

  /// -------------------- DATA GRID ESTABLECIMIENTOS --------------------------
  const mapPrestadorToRow = (p) => ({
    id: p.idPrestador + Math.random() * (1000 - 1),
    idPrestador: p.idPrestador,
    nombre: p.nombre,
    domicilio: p.domicilio,
    localidad: p.localidad,
    telefono: p.telefono,
  });

  const columns = [
    {
      field: 'desplegable',
      headerName: 'Establecimientos',
      flex: 1,
      renderCell: (a) => <DataCollapseDrawer establecimiento={a.row} />,
    },
    { field: 'nombre', headerName: 'Nombre', flex: 1, width: 120 },
    { field: 'telefono', headerName: 'Teléfono', flex: 1 },
    { field: 'domicilio', headerName: 'Domicilio', flex: 1 },
    { field: 'localidad', headerName: 'Localidad', flex: 1 },
  ];

  const handlerSelectLocalidad = (localidad) => {
    setSelectedLocalidad(localidad);
  };

  const handlerSelectTipoEstablecimiento = (tipo) => {
    setSelectedTipoEstablecimiento(tipo);
  };

  const handleBorrarMensaje = () => {
    setError('');
    setAlertResultFind('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ButtonBack marginBotton='1' variant='text' size='small'></ButtonBack>
        <Card sx={{ width: '100%', p: 1 }}>
          <CardContent>
            <Typography gutterBottom variant='h5' component='h2'>
              Buscar Establecimiento
            </Typography>
            <Typography variant='string' component={'div'}>
              Acá podrás consultar los establecimientos prestadores, ingresando alguno de los
              siguientes criterios de búsqueda.
            </Typography>
            <VideoModal
              sx={{ pt: 2 }}
              src={'https://www.youtube.com/embed/vwYdb8axV7o?si=Iaa0quv05vrjVnzq&autoplay=1'}
            />
          </CardContent>

          {visibleFind && (
            <form>
              <CardContent sx={{ pt: 0 }}>
                <FormControl fullWidth={true} variant='outlined'>
                  <TextField
                    variant='standard'
                    type='text'
                    fullWidth
                    margin='normal'
                    name='nombre'
                    label='Nombre'
                    {...register('nombre', {
                      maxLength: {
                        value: 200,
                        message: 'El campo nombre es demasiado largo',
                      },
                      pattern: {
                        value: /^(([a-zñA-ZÑ. ]{1,60}))$/,
                        message: 'El campo nombre debe contener solo letras',
                      },
                    })}
                  />
                  {errors['nombre'] && <p style={{ color: 'red' }}>{errors['nombre'].message}</p>}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <LocalidadAutocomplete
                    key={keyComponentLocalidad}
                    onSelectLocalidad={handlerSelectLocalidad}
                  />
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <TipoEstablecimientoAutocomplete
                    key={keyComponentTipoEstablecimiento}
                    onSelectTipoEstablecimiento={handlerSelectTipoEstablecimiento}
                  />
                </FormControl>
              </CardContent>

              <CardActions sx={{ justifyContent: 'flex-end', p: 2 }}>
                <Stack
                  direction={{ xs: 'column', sm: 'row' }}
                  spacing={{ xs: 1, sm: 2 }}
                  justifyContent={{ xs: 'center', sm: 'flex-end' }}
                  alignItems={{ xs: 'strech' }}
                >
                  <Button
                    variant='contained'
                    size='medium'
                    startIcon={<HighlightOff />}
                    color='neutral'
                    onClick={clean}
                  >
                    Limpiar
                  </Button>
                  <LoadingButton
                    fullWidth={true}
                    type='submit'
                    variant='contained'
                    color='primary'
                    loading={loading ? 'show' : 'hide'}
                    content={'Buscar'}
                    startIcon={<SearchIcon />}
                    onClick={handleSubmit(find)}
                  />
                </Stack>
              </CardActions>
            </form>
          )}

          {!visibleFind && (
            <Grid container direction='column' justifyContent='space-between' spacing={1}>
              <Grid item>
                <div style={{ height: '50vmax', width: '100%' }}>
                  <DataGrid
                    sx={{
                      border: 0,
                      '& .MuiDataGrid-cell': {
                        py: '8px',
                      },
                    }}
                    loading={!prestadores.length}
                    rows={prestadores.map((a) => mapPrestadorToRow(a))}
                    columns={columns}
                    getRowHeight={() => 'auto'}
                    getEstimatedRowHeight={() => 200}
                    columnVisibilityModel={{
                      desplegable: screenWidth === 'xs',
                      nombre: screenWidth !== 'xs',
                      telefono: screenWidth !== 'xs',
                      domicilio: screenWidth !== 'xs',
                      localidad: screenWidth !== 'xs',
                    }}
                    pagination={screenWidth === 'xs' ? null : true}
                    autoPageSize
                    disableColumnMenu
                  />
                </div>
              </Grid>
              <Grid item>
                <Grid container justifyContent='flex-end' spacing={1}>
                  <Grid item>
                    {' '}
                    <Button
                      variant='contained'
                      color='neutral'
                      startIcon={<SearchIcon />}
                      onClick={() => handleBuscarOtro()}
                    >
                      Buscar otro
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Card>
      </Grid>
      <CustomizedSnackbars
        open={alertResultFind}
        severity='info'
        message={alertResultFind}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />
    </Grid>
  );
};

export default BuscarEstablecimiento;
