import React, { useState, useEffect } from 'react';
import CustomizedSnackbars from '../../Notifications/SnackBar';
import { useForm } from 'react-hook-form';
import LoadingButton from '../../../@iosper/components/LoadingButton';
import {
  Button,
  Card,
  CardContent,
  FormControl,
  Grid,
  Typography,
  FormControlLabel,
  Radio,
  Link,
  FormLabel,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  Container,
  Box,
  Stepper,
  Step,
  StepLabel,
  Avatar,
} from '@mui/material';
import { Redirect } from 'react-router-dom';
import ReCaptcha from '../../Common/Recaptcha';
import logo from '../../../assets/images/logo-small.png';
import TerminosCondiciones from '../../Screens/TerminosCondiciones';
import Pass from '../../Common/PasswordTextInput';
import EmailTextInput from '../../Common/EmailTextInput';
import CuilTextInput from '../../Common/CuilTextInput';
import NombrePropioTextInput from '../../Common/NombrePropioTextInput';
import DateTextInput from '../../Common/DateTextInput';
import { useParams } from 'react-router-dom';
import ButtonBack from '../../Breadcrumb/ButtonBack';
import {
  validarCrearCuenta,
  verificarIdentidad,
  verificarIdentidadRecuperarPin,
} from '../../../services/Afiliado/Afiliado';
import ConfirmarContactoCrearCuentaView from './ConfirmarContactoCrearCuenta';
import ConfirmarContactoRecuperarPinView from './ConfirmarContactoRecuperarPin';
import ContactoConfirmadoRedirectSignin from './ContactoConfirmadoRedirectSignin';
import CuestionarioValidacionIdentidad from './CuestionarioValidacionIdentidad';
import ContactoConfirmado from '../../Contacto/ContactoConfirmado';
// import { VideoModal } from '../../Common/VideoModal';

const CrearCuenta = (props) => {
  let { validarIdentidad } = useParams();
  const [error, setError] = useState('');
  const [errorSexo, setErrorSexo] = useState(false);
  const [helperSexo, setHelperSexo] = useState('');
  const [errorAcuerdo, setErrorAcuerdo] = useState(false);
  const [helperAcuerdo, setHelperAcuerdo] = useState('');
  const [errorCorreo, setErrorCorreo] = useState('');
  const [openConfirmarCuenta, setOpenConfirmarCuenta] = useState(false);
  const [errorContrasena, setErrorContrasena] = useState('');
  const [openConfirmarContactoRecuperarPin, setOpenConfirmarContactoRecuperarPin] = useState(false);
  const [datosCuentaReceived, setDatosCuentaReceived] = useState({});
  const [openContactoConfirmado, setOpenContactoConfirmado] = useState(false);
  const [onRedirectPerfil, setRedirectPerfil] = useState(false);

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: 'onBlur' });

  const [sexo, setSexo] = useState('');
  const [aceptaCondiciones, setAceptaCondiciones] = useState(false);

  const [onCancelar, setOnCancelar] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openTerminosCondiciones, setOpenTerminosCondiciones] = useState(false);
  const [response, setResponse] = useState('');
  const [recaptchaValidado, setRecaptchaValidado] = useState(false);
  const [redirectSignin, setRedirectSignin] = useState(false);
  const [cuil, setCuil] = useState(null);

  const [stepPreguntasNext, setStepPreguntasNext] = useState(false);
  const [respuestas, setRespuestas] = useState(null);
  const [enableSiguiente, setEnableSiguiente] = useState(false);
  const [resetCuestionario, setResetCuestionario] = useState(false);

  const [recuperarPin, setRecuperarPin] = React.useState(props?.recuperarPin || false);

  useEffect(() => {
    setRecuperarPin(props?.recuperarPin);
    //setLoading(props?.loading === 'show' ? true : false)
  }, [props]);

  const onRecaptchaValidado = (validado) => {
    setLoading(false);
    setRecaptchaValidado(validado);
  };
  const onRecaptchaChange = () => {
    setLoading(true);
  };
  const onRecaptchaError = (e) => {
    setLoading(false);
    setError(e);
  };
  const cancelarHandler = () => {
    setOnCancelar(true);
  };

  const handlerCambiarCuenta = async (data, e) => {
    e.preventDefault();
    setErrorCorreo('');
    setErrorContrasena('');
    if (activeStep !== steps.length - 1) {
      if (activeStep === steps.length - 3) {
        if (sexo === '') {
          setErrorSexo(true);
          setHelperSexo('Debe seleccionar el sexo');
          return;
        } else {
          handleNextStep(); //si no hay error y si no es el ultimo paso, salto...
          return;
        }
      } else {
        handleNextStep(); //si no es el ultimo paso, salto...
        return;
      }
    }

    let validate = true;
    if (!validarIdentidad) {
      if (data.correo !== data.confirmarCorreo) {
        setErrorCorreo('Los correos no coinciden');
        validate = false;
        setLoading(false);
      }
      if (data.nuevopin !== data.confirmarnuevopin) {
        setErrorContrasena('Las contraseñas no coinciden');
        validate = false;
        setLoading(false);
      }
      if (!aceptaCondiciones) {
        setErrorAcuerdo(true);
        setHelperAcuerdo('Debe aceptar términos y condiciones');
        return;
      }

      if (validate) {
        const cuenta = {
          cuil: data.cuil,
          nombres: data.nombres.trim(),
          apellido: data.apellido.trim(),
          fechaNacimiento: data.fechaNacimiento.trim(),
          sexo: sexo.trim(),
          correo: data.correo.trim(),
          confirmarCorreo: data.confirmarCorreo.trim(),
          contrasena: data.nuevopin,
          confirmarContrasena: data.confirmarnuevopin,
          aceptaCondiciones: aceptaCondiciones,
          preguntas: respuestas,
        };
        setLoading(true);
        setResponse('');
        setError('');
        try {
          //llamar al endpoint
          const respuesta = recuperarPin
            ? await verificarIdentidadRecuperarPin(cuenta)
            : await validarCrearCuenta(cuenta);

          if (respuesta === 'CORREO_ENVIADO') {
            //abrir vsita confirmar correo
            if (recuperarPin) {
              setOpenConfirmarContactoRecuperarPin(true);
            } else {
              setOpenConfirmarCuenta(true);
            }
            setDatosCuentaReceived(cuenta);
          }
          // setResponse("Correo enviado...");
          //   reset();
          setLoading(false);
        } catch (e) {
          setActiveStep(0);
          setResetCuestionario(true);
          setLoading(false);
        }
      }
    } else {
      if (!aceptaCondiciones) {
        setErrorAcuerdo(true);
        setHelperAcuerdo('Debe aceptar términos y condiciones');
        return;
      }
      const cuenta = {
        cuil: data.cuil,
        nombres: data.nombres,
        apellido: data.apellido,
        fechaNacimiento: data.fechaNacimiento,
        sexo: sexo,
        aceptaCondiciones: aceptaCondiciones,
        respuestas: respuestas,
      };
      setLoading(true);
      setResponse('');
      setError('');
      //setError("FALTA llamar a endpoint validarIdentidad para enviar los siguientes datos: "+ JSON.stringify(cuenta))

      let verificar = verificarIdentidad(cuenta);
      verificar
        .then((res) => {
          setLoading(false);
          setRedirectPerfil(true);
          setResponse('Su identidad fue verificada con éxito');
        })
        .catch((e) => {
          setLoading(false);
          setActiveStep(0);
          setResetCuestionario(true);
        });
    }
  };
  const handleClickOpenTerminosCondiciones = () => {
    setOpenTerminosCondiciones(true);
  };
  const handleCloseTerminosCondiciones = () => {
    setOpenTerminosCondiciones(false);
  };

  const handleCloseContactoConfirmado = () => {
    setOpenContactoConfirmado(false);
    setRedirectSignin(true);
  };

  useEffect(() => {}, []);

  const steps = ['Paso 1', 'Paso 2', 'Paso 3'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [skipped, setSkipped] = React.useState(new Set());

  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNextStep = () => {
    if (!_canSkip()) {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }

      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function _canSkip() {
    if (
      errors['cuil'] ||
      errors['nombres'] ||
      errors['apellido'] ||
      errors['fechaNacimiento'] ||
      errors['sexo']
    ) {
      return true;
    } else {
      return false;
    }
  }

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // Probablemente quieras protegerte contra algo como esto,
      // nunca debería ocurrir a menos que alguien esté tratando activamente de romper algo.
      throw new Error('No puedes omitir un paso que no es opcional.');
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handlerTextoBotonSubmit = () => {
    let label = 'Crear';
    if (validarIdentidad) {
      label = 'Validar';
    } else if (recuperarPin) {
      label = 'Recuperar'; //recuperar pin
    }

    return label;
  };

  const handleReset = () => {
    setActiveStep(0);
    reset();
    setResetCuestionario(true);
  };

  const handleRadioSexoChange = (event) => {
    setSexo(event.target.value);
    setHelperSexo('');
    setErrorSexo(false);
  };

  const handleChangeAcuerdo = (e) => {
    setAceptaCondiciones(e.target.checked);
    setHelperAcuerdo('');
    setErrorAcuerdo(false);
  };

  const handleSiguiente = (event) => {
    // step del cuestionario
    if (activeStep === 1) {
      setStepPreguntasNext(true);
    }
  };

  const handleRespuestasCuestionario = (value) => {
    setResetCuestionario(false);
    setRespuestas(value);
    setStepPreguntasNext(false);
  };

  const handleBorrarMensaje = () => {
    setError('');
    setResponse('');
  };

  function _renderStepContent(step) {
    switch (step) {
      case 0:
        return (
          <React.Fragment>
            <FormControl fullWidth={true}>
              <CuilTextInput
                register={register}
                errors={setError}
                required={true}
                change={(value) => {
                  setCuil(value);
                }}
                label='CUIL'
                fieldName='cuil'
                variant='standard'
              />
              {errors['cuil'] && <p style={{ color: 'red' }}>{errors['cuil'].message}</p>}
              <Link
                target='_blank'
                rel='noopener'
                href='https://www.anses.gob.ar/consulta/constancia-de-cuil'
                underline='hover'
              >
                {'¿Dónde averiguo mi CUIL?'}
              </Link>
            </FormControl>
            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              <NombrePropioTextInput
                register={register}
                errors={setError}
                required={true}
                label='Nombres'
                fieldName='nombres'
              />
              {errors['nombres'] && <p style={{ color: 'red' }}>{errors['nombres'].message}</p>}
            </FormControl>

            <FormControl fullWidth={true} variant='outlined' margin='none'>
              <NombrePropioTextInput
                register={register}
                errors={setError}
                required={true}
                label='Apellido'
                fieldName='apellido'
              />
              {errors['apellido'] && <p style={{ color: 'red' }}>{errors['apellido'].message}</p>}
            </FormControl>

            <FormControl fullWidth={true} variant='outlined' margin='normal'>
              {/* <DateTextInputV2
                register={register}
                errors={setError}
                required={true}
                label="Fecha Nacimiento"
                fieldName="fechaNacimiento"
                variant={'standard'}
              />
              */}
              <DateTextInput
                register={register}
                errors={setError}
                required={true}
                label='Fecha Nacimiento'
                fieldName='fechaNacimiento'
              />
              {errors['fechaNacimiento'] && (
                <p style={{ color: 'red' }}>{errors['fechaNacimiento'].message}</p>
              )}
            </FormControl>

            <FormControl fullWidth={true} variant='outlined'>
              <FormLabel id='radio-label'>Sexo (según tu documento)</FormLabel>
              <div>
                <FormControlLabel
                  label='Femenino'
                  control={
                    <Radio
                      checked={sexo === 'Femenino'}
                      onChange={handleRadioSexoChange}
                      value='Femenino'
                      name='radio-button-demo'
                      label='Femenino'
                    />
                  }
                />
                <FormControlLabel
                  label='Masculino'
                  control={
                    <Radio
                      checked={sexo === 'Masculino'}
                      onChange={handleRadioSexoChange}
                      value='Masculino'
                      name='radio-button-demo'
                      aria-label='Masculino'
                    />
                  }
                />
                <FormControlLabel
                  label='Otro'
                  control={
                    <Radio
                      checked={sexo === 'Otro'}
                      onChange={handleRadioSexoChange}
                      value='Otro'
                      name='radio-button-demo'
                      aria-label='Otro'
                    />
                  }
                />
              </div>
              {errorSexo && <p style={{ color: 'red' }}>{helperSexo}</p>}
            </FormControl>

            {/* Comentamos por ahora hasta que esté autorizado lo de RENAPER
        <FormControl fullWidth={true}  >   
          <NumeroTramiteDocumento  register={register}  errors={setError}  required={true} variant="standard" 
                        label="N° de trámite de DNI (11 dígitos)" fieldName="numeroTramite" />                    
                          {errors['numeroTramite'] && (
                    <p style={{ color: "red" }}>{errors['numeroTramite'].message}</p>
                )}
                          
        </FormControl> 
      */}
          </React.Fragment>
        );

      case 1:
        return (
          <React.Fragment>
            <CuestionarioValidacionIdentidad
              cuil={cuil}
              onEnableSiguiente={(value) => {
                setEnableSiguiente(value);
              }}
              onSiguiente={stepPreguntasNext}
              respuestas={respuestas}
              onResultRespuesta={(value) => handleRespuestasCuestionario(value)}
              onResetCuestionario={resetCuestionario}
            />
          </React.Fragment>
        );

      case 2:
        return (
          <React.Fragment>
            {!validarIdentidad && (
              <div>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <EmailTextInput
                    register={register}
                    errors={setError}
                    required={true}
                    label='Correo Electrónico'
                    fieldName='correo'
                    mensajeError='Debe ingresar un correo electrónico'
                  />
                  {(errors['correo'] || errorCorreo) && (
                    <p style={{ color: 'red' }}>{errors['correo']?.message || errorCorreo}</p>
                  )}
                </FormControl>

                <FormControl fullWidth={true} variant='outlined' margin='none'>
                  <EmailTextInput
                    register={register}
                    errors={setError}
                    required={true}
                    label='Confirmar Correo Electrónico'
                    fieldName='confirmarCorreo'
                    mensajeError='Debe confirmar un correo electrónico'
                  />
                  {(errors['confirmarCorreo'] || errorCorreo) && (
                    <p style={{ color: 'red' }}>
                      {errors['confirmarCorreo']?.message || errorCorreo}
                    </p>
                  )}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='normal'>
                  <Pass
                    register={register}
                    errors={setError}
                    label='Nueva Contraseña'
                    autoComplete='new-password'
                    fieldName='nuevopin'
                  />
                  {(errors['nuevopin'] || errorContrasena) && (
                    <p style={{ color: 'red' }}>
                      {errors['nuevopin']?.message || errorContrasena}{' '}
                    </p>
                  )}
                </FormControl>
                <FormControl fullWidth={true} variant='outlined' margin='none'>
                  <Pass
                    register={register}
                    errors={setError}
                    label='Confirmar Nueva Contraseña'
                    autoComplete='new-password'
                    fieldName='confirmarnuevopin'
                  />
                  {(errors['confirmarnuevopin'] || errorContrasena) && (
                    <p style={{ color: 'red' }}>
                      {errors['confirmarnuevopin']?.message || errorContrasena}
                    </p>
                  )}
                  <Typography variant='body2' color='textSecondary' component='p' align='center'>
                    La contraseña debe tener un mínimo de 8 caracteres, 4 letras y 4 números que no
                    deben ser consecutivos o iguales.
                  </Typography>
                </FormControl>
              </div>
            )}
            <FormControl variant='outlined' margin='none'>
              <p>
                <b>Es necesario que aceptes los</b>{' '}
                <Link underline='hover' onClick={handleClickOpenTerminosCondiciones}>
                  {'Términos y Condiciones'}
                </Link>{' '}
                <br></br>
                <FormControlLabel
                  label='Acepto los términos y condiciones'
                  control={
                    <Checkbox
                      onChange={handleChangeAcuerdo}
                      checked={aceptaCondiciones}
                      name='radio-button-Terminos-Condiciones'
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                />
              </p>
              {errorAcuerdo && <p style={{ color: 'red' }}>{helperAcuerdo}</p>}
            </FormControl>
            <ReCaptcha
              sitekey={process.env.REACT_APP_IOSPER_RECAPTCHA_SITE_KEY_SECRET}
              onRecaptchaValidado={onRecaptchaValidado}
              onChange={onRecaptchaChange}
              onError={onRecaptchaError}
            />
          </React.Fragment>
        );

      default:
        return <div>Not Found</div>;
    }
  }

  return onRedirectPerfil ? (
    <Redirect
      to={{
        pathname: '/perfil',
        state: { from: '/crearCuenta' },
      }}
    />
  ) : onCancelar || redirectSignin ? (
    <Redirect
      to={{
        pathname: '/',
        state: { from: '/signin' },
      }}
    />
  ) : (
    <Container component='main' maxWidth='sm' style={{ padding: '0' }}>
      <ButtonBack />

      <Card>
        <CardContent sx={{ my: { xs: 2, md: 4 }, mx: { xs: 2, md: 5 } }}>
          <Grid
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            gap={2}
            mb={4}
          >
            {!validarIdentidad && !recuperarPin && (
              <Avatar alt='IOSPER' src={logo} sx={{ width: 70, height: 70 }} />
            )}

            <Typography gutterBottom variant='h5' component='h2'>
              {validarIdentidad && 'Validar Identidad'}
              {!validarIdentidad && !recuperarPin && 'Crear Cuenta mi.iosper'}
            </Typography>
            {/* {!validarIdentidad && !recuperarPin && (
              <VideoModal
                sx={{ pt: 1 }}
                src={'https://www.youtube.com/embed/aYJ3xptVArY?si=roewxtyU5kpUZ8FD&autoplay=1'}
              />
            )} */}
            {recuperarPin && (
              <Typography variant='h6' component='h5'>
                Estas intentando cambiar contraseña, por favor valida tu identidad:
              </Typography>
            )}
          </Grid>

          <form id='formulario' onSubmit={handleSubmit(handlerCambiarCuenta)}>
            <CardContent>
              <Box sx={{ width: '100%' }}>
                <Stepper activeStep={activeStep}>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>
                        <Typography sx={{ display: { xs: 'none', sm: 'block' } }}>
                          {label}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
                </Stepper>
                {activeStep === steps.length ? (
                  <React.Fragment>
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      <Button onClick={handleReset}>Reiniciar</Button>
                    </Box>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <br />
                    {_renderStepContent(activeStep)}
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Button disabled={activeStep === 0} onClick={handleBack}>
                        Anterior
                      </Button>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {/*isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Saltar
              </Button>
            )*/}
                      {/*<Button onClick={handleReset}>Cancelar</Button>*/}

                      {activeStep === steps.length - 1 ? (
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 0 }}>
                          <LoadingButton
                            variant='contained'
                            color='primary'
                            type='submit'
                            disabled={!recaptchaValidado}
                            loading={loading ? 'show' : 'hide'}
                            content={handlerTextoBotonSubmit}
                          />
                        </Box>
                      ) : (
                        <Button
                          variant='contained'
                          size='medium'
                          type='submit'
                          color='primary'
                          disabled={activeStep == 1 && !enableSiguiente}
                          onClick={(e) => {
                            handleSiguiente(e);
                          }}
                        >
                          Siguiente
                        </Button>
                      )}
                    </Box>
                  </React.Fragment>
                )}
              </Box>
            </CardContent>
          </form>
        </CardContent>
      </Card>

      <CustomizedSnackbars
        open={response}
        severity='success'
        message={response}
        onDeleteMessage={handleBorrarMensaje}
      />
      <CustomizedSnackbars
        open={error}
        severity='error'
        message={error}
        onDeleteMessage={handleBorrarMensaje}
      />

      <Dialog open={openTerminosCondiciones} onClose={handleCloseTerminosCondiciones}>
        <DialogContent>
          <Grid container spacing={1}>
            <Grid item>
              <TerminosCondiciones></TerminosCondiciones>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTerminosCondiciones}>Cerrar</Button>
        </DialogActions>
      </Dialog>

      <ConfirmarContactoCrearCuentaView
        setOpen={setOpenConfirmarCuenta}
        open={openConfirmarCuenta}
        setRedirectSignin={setRedirectSignin}
        datosCuenta={datosCuentaReceived}
        respuestas={respuestas}
      ></ConfirmarContactoCrearCuentaView>

      <ConfirmarContactoRecuperarPinView
        open={openConfirmarContactoRecuperarPin}
        setRedirectSignin={setOpenContactoConfirmado}
        setOpen={setOpenConfirmarContactoRecuperarPin}
        datosCuenta={datosCuentaReceived}
        respuestas={respuestas}
      ></ConfirmarContactoRecuperarPinView>

      <ContactoConfirmadoRedirectSignin
        setOpen={setOpenConfirmarCuenta}
      ></ContactoConfirmadoRedirectSignin>

      <ContactoConfirmado open={openContactoConfirmado} onClose={handleCloseContactoConfirmado} />
    </Container>
  );
};

export default CrearCuenta;
